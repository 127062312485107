import React, { useRef, useEffect } from "react";
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import EmailIcon from '@mui/icons-material/Email';
import GitHubIcon from '@mui/icons-material/GitHub'; 
import DescriptionIcon from '@mui/icons-material/Description';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import JesseCV from "../assets/Jesse Washburn CV for Website.pdf"; 
import "../styles/Home.css";

function Home() {
  const skillsRef = useRef(null);

  useEffect(() => {
    document.title = "Jesse Washburn";
  }, []);

  const scrollToSkills = () => {
    if (skillsRef.current) {
      skillsRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <div className="home">
      <div className="about">
        <h2> Jesse Washburn</h2>
        <div className="prompt">
          <p>A software developer with a foundation in business development and data analytics.</p>
          <a href="https://www.linkedin.com/in/jesse-washburn-24467428b/" target="_blank" rel="noopener noreferrer">
            <LinkedInIcon />
          </a>
          <a href="mailto:jeswashburn@gmail.com">
            <EmailIcon />
          </a>
          <a href="https://github.com/jessewashburn" target="_blank" rel="noopener noreferrer">
            <GitHubIcon />
          </a>  
          <a href={JesseCV} download="JesseWashburn_CV" className="download-cv">
            <DescriptionIcon />
          </a>
        </div>
      </div>
      <div className="skills" ref={skillsRef}>
        <h1> Skills</h1>
        <ol className="list">
          <li className="item">
            <h2> Languages</h2>
            <span>
              Javascript, Typescript, Python, Java, C#, SQL, R
            </span>
          </li>
          <li className="item">
            <h2>Frameworks</h2>
            <span>
              React Native Web, Node.JS, Angular, Spring, ASP.NET, Flutter
            </span>
          </li>
          <li className="item">
            <h2>Software</h2>
            <span> Docker, AWS, Git, Azure, MySQL, Google Cloud VM, Jira, Tableau, Excel</span>
          </li>
        </ol>
      </div>

      <div className="scroll-down-arrow" onClick={scrollToSkills}>
        <div className="arrow-wrapper" style={{ animation: "bounce 1s infinite alternate" }}>
          <ArrowDownwardIcon style={{ fontSize: 40 }} />
        </div>
      </div>
    </div>
  );
}

export default Home;
