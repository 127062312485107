import React from "react";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import SchoolIcon from '@mui/icons-material/School';
import WorkIcon from '@mui/icons-material/Work';

function Experience() {
  return (
    <div className="experience">
      <VerticalTimeline lineColor="#3e497a">

      <VerticalTimelineElement
          className="vertical-timeline-element--work"
          date="2024-present"
          iconStyle={{ background: "#e9d35b", color: "#fff" }}
          icon={<WorkIcon />}
        >
          <h3 className="vertical-timeline-element-title">
          Software Engineering Intern - Open Learning Exchange
          </h3>
          <h4 className="vertical-timeline-element-subtitle">
          Cambridge, MA/Remote
          </h4>
          <p>Javascript, Typescript, Node JS, Angular, CouchDB, Docker, Git</p>
        </VerticalTimelineElement>

          <VerticalTimelineElement
          className="vertical-timeline-element--education"
          date="2023-2026"
          iconStyle={{ background: "#3e497a", color: "#fff" }}
          icon={<SchoolIcon />}
        >
          <h3 className="vertical-timeline-element-title">
          Towson University
          </h3>
          <h4 className="vertical-timeline-element-subtitle">
          Towson, MD
          </h4>
          <p> B.S Computer Science</p>
        </VerticalTimelineElement>

        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          date="2022 - present"
          iconStyle={{ background: "#e9d35b", color: "#fff" }}
          icon={<WorkIcon />}
        >
          <h3 className="vertical-timeline-element-title">
          Digital Operations Manager - Woodcraft Artisans
          </h3>
          <h4 className="vertical-timeline-element-subtitle">
          Annapolis, MD
          </h4>
          <p>CRM Integration, Web design, E-commerce management</p>
        </VerticalTimelineElement>

        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          date="2015 - 2022"
          iconStyle={{ background: "#e9d35b", color: "#fff" }}
          icon={<WorkIcon />}
        >
          <h3 className="vertical-timeline-element-title">
          Founder/C.O.O - Let'sGrowDC
          </h3>
          <h4 className="vertical-timeline-element-subtitle">
            Washington D.C
          </h4>
          <p>Product Management, Project Management, Key-Account Strategy, Leadership, Facilitated company acquisition</p>
        </VerticalTimelineElement>



        <VerticalTimelineElement
          className="vertical-timeline-element--education"
          date="2010 - 2012"
          iconStyle={{ background: "#3e497a", color: "#fff" }}
          icon={<SchoolIcon />}
        >
          <h3 className="vertical-timeline-element-title">
            Anne Arundel Community College 
          </h3>
          <h4 className="vertical-timeline-element-subtitle">
            Annapolis, MD
          </h4>
          <p> A.A Business Management</p>
        </VerticalTimelineElement>
      </VerticalTimeline>
    </div>
  );
}

export default Experience;
